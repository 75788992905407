.keystone-label-input {

}

.keystone-label-input-results {
    position: absolute;
    top: 100%;
    width: 100%;
    z-index: 2;
}

@media only screen and (max-width: 767px) {
}