:root {
  --white: white;
  --black: black;
  --black-8: rgba(0, 0, 0, 0.08);
  --black-60: rgba(0, 0, 0, 0.6);
  --blue-black-4:rgb(248, 249, 251);
  --blue-black-8: rgb(241, 242, 246);
  --blue-black-16: rgb(222, 224, 232);
  --text-brand: rgb(25, 27, 28);
  --text-brand-light: rgb(115, 127, 133);
  --text-brand-disabled: rgb(133, 145, 149);
  --orange-lighter: #f7f4f0;
  --orange-light: #e68e00;
  --orange-dark: #f0592c;
  --orange-border: #a5530c;
  --green-light: #2dcfa9;
  --green-dark: #21ac5d;
  --green-border: #1f785c;
  --yellow-light: #edc511;
  --yellow-dark: #f0b22c;
  --yellow-border: #b87f15;
  --red-light: #ef4a28;
  --red-dark: #c52525;
  --red-border: #a32424;
  --blue-light: #a9bee6;
  --blue-dark: #706ada;

  --background: #f6f6f6;
  --header-height: 56px;
  --footer-height: 64px;
  --white-transparent: rgba(255, 255, 255, 0.12);
  --max-content-width: 1488px;
  --max-modal-width: 640px;
}

.border-100 {
  border-radius: 100%;
}

@font-face {
  font-family: 'SF Mono';
  src: url('./assets/fonts/SF-Mono-Regular.otf') format('opentype');
  font-weight: normal; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Mono';
  src: url('./assets/fonts/SF-Mono-Medium.otf') format('opentype');
  font-weight: 500; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Mono';
  src: url('./assets/fonts/SF-Mono-Bold.otf') format('opentype');
  font-weight: bold; /* Specify the weight of the font; Change if necessary */
  font-style: normal;  /* Specify the style of the font; Change if necessary */
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF-Pro-Text-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SF Pro';
  src: url('./assets/fonts/SF-Pro-Text-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

/* POPPINS FONT */
@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url('./assets/fonts/Poppins-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

*, *::before, *::after {
  box-sizing: border-box;
}

:root {
  --ks-brand-color: rgb(255, 221, 87);
  --yellow: #ffdd57;
  --ks-brand-color-hover: #ffd746;
  --ks-brand-color-light: #fcefc0;
  --ks-brand-color-dark: #e09f07;
  --ks-divider: #E6E8F0;
  --blue: rgb(155, 176, 255);
  --blue-text: rgb(36, 40, 55);
  --ks-divider-transparent: rgba(0,0,0,0.24);
  --ks-divider-transparent-light: rgba(0,0,0,0.12);
  --ks-divider-light: #f2f4f8;
  --ks-divider-superlight: #f6f8fb;
  --ks-blue: #DDECEC;
  --ks-mint: #E6F4F1;
  --grey: #f4f6fd;
  --ks-divider-dark: #b9bfd6;
  --ks-positive: #079f80;
  --ks-positive-text: rgb(38, 92, 38);
  --ks-positive-background: rgb(196, 236, 196);
  --ks-negative-text: #b60b0b;
  --ks-negative-background: #f7e0e2;
  --ks-negative: #e64040;
  --ks-brand-secondary: #372d5b;
  --ks-brand-tertiary: #C8D2A4;
  --ks-blue-dark: #6f7aa5;
  --ks-box-shadow: 0 12px 32px rgba(230, 232, 240, 0.5);
  --ks-box-shadow-up: 0 -12px 32px rgba(230, 232, 240, 0.5);
  --ks-box-shadow-gray: 0 12px 24px rgba(0, 0, 0, 0.04);
  --ks-box-shadow-shallow: 0 2px 8px rgba(230, 232, 240, 0.5);
  --ks-text-color: #333;
  --ks-negative-color: #e64040;
  --ks-dark-blue: #1c6fff;
  --ks-green: #00b894;
  --ks-primary-gradient: linear-gradient(      /* Gradient for over 50% */
      to top,
      #6c9cef,
      #0857de,
      #ffffff
  );
  --ks-under-gradient: linear-gradient(        /* Gradient for under 50% */
      to top,
      #ff6666,
      #ff9999,
      #ffffff
  );
  --ks-center-line-color: #003D33;             /* Darker line for contrast */
  --ks-divider: #E6E8F0;                       /* Keeping the original */
}

input[type="checkbox"] {
  box-sizing: border-box;
  min-width: 20px;
  min-height: 20px;
  max-width: 20px;
  max-height: 20px;
  padding: 0;
  border: 2px solid var(--black);
  appearance: none;
  background-color: transparent;
  outline: none;
  transition: outline 0.1s;
  margin: 0;
  border-radius: 4px;
}

input[type="checkbox"]:checked {
  background-size: cover;
}

input[type="checkbox"]:not(:disabled):checked {
  border-color: var(--black);
  background-color: var(--black);
  background-image: url('./media/check-white32@3x.png');
}

.flex-1 {
  display: flex;
  flex: 1;
}

.no-decoration {
  text-decoration: none;
}

.flex-0-1 {
  flex: 0 1;
}

.white-bg {
  background-color: var(--white);
}

.white {
  color: var(--white);
}

.blue-text {
  color: var(--blue-text);
}

.yellow {
  color: var(--yellow);
}

.fixed {
  position: fixed;
}

.flex-wrap {
  flex-wrap: wrap;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(196px, 1fr));
  grid-auto-rows: 1fr;
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  gap: 0.6rem;
}

.grid-featured {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-auto-rows: 1fr;
  /* This is better for small screens, once min() is better supported */
  /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
  gap: 0.6rem;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.overflow-y-scroll {
  overflow-y: scroll;
}

.row {
  border-radius: 6px;  
  position: relative;
  display: flex;
  background-color: white;
  border: 1px solid var(--ks-divider);
  box-shadow: var(--ks-box-shadow-shallow);
}

.row-clickable {
  transition: all 0.2s ease-in-out;
  cursor: pointer;
}

.row-clickable:hover {
  position: relative;
  border: 1px solid var(--ks-divider-dark);
}

.row-clickable:active {
    scale: 0.98;
}

.max-w-desktop {
  max-width: 960px;
}
.max-100vh {
  max-height: calc(100vh - var(--header-height));
}

*, *::before, *::after {
  box-sizing: border-box;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

html, body, #root, #store {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  background-color: var(--background);
  width: 100vw;
  height: 100vh;
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overflow: hidden;
}

#root {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.shop-max-width {
  max-width: var(--max-content-width);
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 6rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  border-radius: 50%;
  animation: pulsOut 1.8s ease-in-out infinite;
  filter: drop-shadow(0 0 1rem rgba(179, 185, 207, 0.75));
}
.loader:before {
  width: 100%;
  padding-bottom: 100%;
  box-shadow: inset 0 0 0 1rem rgb(179, 185, 207);
  animation-name: pulsIn;
}
.loader:after {
  width: calc(100% - 2rem);
  padding-bottom: calc(100% - 2rem);
  box-shadow: 0 0 0 0 #B3B9CF;
}

@keyframes pulsIn {
  0% {
    box-shadow: inset 0 0 0 1rem #B3B9CF;
    opacity: 1;
  }
  50%, 100% {
    box-shadow: inset 0 0 0 0 #B3B9CF;
    opacity: 0;
  }
}

@keyframes pulsOut {
  0%, 50% {
    box-shadow: 0 0 0 0 #B3B9CF;
    opacity: 0;
  }
  100% {
    box-shadow: 0 0 0 1rem #B3B9CF;
    opacity: 1;
  }
}
    
    

#loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 3s infinite ease-in-out;
}

#app {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#app.sidebar {
  align-items: flex-start;
}

#app.pdf-view {
  background-color: var(--white);
  height: initial;
}

p, button, input, td {
  font-family: "Poppins", sans-serif;
}

button {
  transition: all 0.1s ease-in-out;
  border-radius: 6px;
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 600;
  color: var(--white);
  box-shadow: 0 4px 12px var(--black-8);
  background: linear-gradient(to bottom, var(--orange-light), var(--orange-dark));
  border: 1px solid var(--orange-border);
  border-radius: 32px;
  line-height: 22px;
  box-sizing: border-box;
}

p {
  padding: 0;
  margin: 0;
}

#logo-new {
  height: 32px;
}

#logo-new.sidebar {
  margin-top: 24px;
}

#header {
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  box-shadow: 0 4px 12px var(--black-8);
  z-index: 2;
  background-color: var(--white);
}

#header.sidebar {
  width: 20%;
  width: 320px;
  background-color: initial;
  box-shadow: initial;
  z-index: 1;
  height: 100vh;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: none;
  padding: 32px 32px 24px 32px;
  justify-content: flex-end;
}

.label {
  color: var(--text-brand);
  font-size: 16px;
  font-weight: 600;
}


.content-label {
  color: var(--text-brand);
  font-size: 16px;
  font-weight: 600;
  line-height: 14px;
  padding-bottom: 2px;
}

.label.small {
  font-size: 14px;
}

select {
  padding: 8px 0 8px 8px;
  border: none;
  background-color: transparent;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-brand);
  cursor: pointer;
  outline: none;
  width: 100%;
  border-right: 12px solid transparent;
}

.card {
  display: flex;
  background-color: var(--white);
  box-shadow: 0 4px 12px var(--black-8);
  padding: 16px;
  border-radius: 12px;
  row-gap: 12px;
  flex-direction: column;
}

.vertical-card-wrapper {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  flex: 1;
}

.horizontal-card-wrapper {
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  flex: 1;
}

.centered {
  align-items: center;
  justify-content: center;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 132px; /* Adjusted width for text */
  height: 36px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.toggle-button-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--blue-black-8);
  -webkit-transition: .4s;
  transition: .4s;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0px; /* Provide some padding for the text */
  border-radius: 6px;
}

.toggle-button-slider:before {
  content: "";
  height: 28px;
  width: 58px;
  position: absolute;
  bottom: 4px;
  background: var(--white); 
  -webkit-transition: .4s;
  transition: .4s;
  z-index: 2;
  left: 4px; /* Adjust if needed */
  border-radius: 4px;
  box-shadow: 0 4px 12px var(--black-8);
}

input:checked + .toggle-button-slider:before {
  -webkit-transform: translateX(66px); /* Adjust distance */
  -ms-transform: translateX(66px);
  transform: translateX(66px);
}

/* Styling texts inside the slider */
.toggle-button-slider .text {
  color: var(--text-brand-light);
  font-size: 14px;
  z-index: 1;
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 2;
  font-weight: 600;
  transition: all 0.3s ease-in-out;
}

.toggle-button-slider .text.active {
  color: var(--text-brand);
}

.divider {
  flex: 1;
  display: flex;
  min-height: 1px;
  max-height: 1px;
  background-color: var(--blue-black-8);
  width: 100%;
}

.info {
  stroke: var(--text-brand-light);
}

input[type="range"] {
  -webkit-appearance: none;
  width: 100%;
  height: 8px;
  border-radius: 4px;
  background: var(--blue-black-4);
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  background: linear-gradient(to bottom, var(--orange-light), var(--orange-dark));
  border: 1px solid var(--orange-border);
  border-radius: 100%;
  cursor: pointer;
}

h3, h2 {
  margin: 0;
  color: var(--text-brand);
  font-weight: 600;
}

h2 {
  font-size: 24px;
}

.vertical-flex-1 {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.m-t-2 {
  margin-top: 16px;
}

.end {
  justify-content: flex-end;
}

#pdf-view {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 9999;
}

.row-gap-12 {
  row-gap: 12px;
}

.p-top-16 {
  margin-top: 16px;
}

.gravity-left {
  justify-content: flex-start;
}

.invalid {
  border: 1px solid var(--orange-dark) !important;
}

.mw-90 {
  max-width: 90%;
}

.top {
  align-items: flex-start;
}

.m-r-12 {
  margin-right: 12px;
}

.space-between {
  justify-content: space-between;
}

.bold {
  font-weight: 600;
}

.medium {
  font-weight: 500;
}

.m-b-8 {
  margin-bottom: 8px;
}

.m-b-12 {
  margin-bottom: 12px;
}

.m-b-24 {
  margin-bottom: 24px;
}

.m-b-48 {
  margin-bottom: 48px;
}

.m-b-64 {
  margin-bottom: 64px;
}

.content {
  font-size: 10px;
  line-height: 14px;
  padding-bottom: 4px;
}

.italics {
  font-style: italic;
}

.p-r-8 {
  padding-right: 8px;
}

.p-l-8 {
  padding-left: 8px;
}

.overflow-hidden {
  overflow: hidden;
}

@media only screen and (max-width: 767px) {
  .max-100vh {
    padding-bottom: 132px;
  }

  .grid-featured {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    /* This is better for small screens, once min() is better supported */
    /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
    gap: 0.6rem;
  }
}

.hide {
  opacity: 0;
}

/* SEMANTICS */

.size-xs {
  font-size: 10px;
  line-height: 12px;
}

.line-xs {
  line-height: 14px;
}

.size-s {
  font-size: 12px;
  line-height: 14px;
}

.line-s {
  line-height: 16px;
}

.size-m {
  font-size: 14px;
  line-height: 16px;
}

.line-m {
  line-height: 20px;
}

.size-l {
  font-size: 16px;
  line-height: 18px;
}

.size-xl {
  font-size: 18px;
  line-height: 20px;
}

.line-xl{
  line-height: 32px;
}

.size-display {
  font-size: 24px;
  line-height: 26px;
}

.pad-xs {
  padding: 8px;
}

.pad-s {
  padding: 12px;
}

.pad-m {
  padding: 16px;
}

.pad-l {
  padding: 24px;
}

.pad-xl {
  padding: 32px;
}

.pad-h-xs {
  padding-left: 8px;
  padding-right: 8px;
}

.pad-h-s {
  padding-left: 12px;
  padding-right: 12px;
}

.pad-h-m {
  padding-left: 16px;
  padding-right: 16px;
}

.pad-h-l {
  padding-left: 24px;
  padding-right: 24px;
}

.pad-h-xl {
  padding-left: 32px;
  padding-right: 32px;
}

.no-pad-right {
  padding-right: 0;
}

.no-pad-left {
  padding-left: 0;
}

.v-gap-xxs {
  row-gap: 2px;
}

.v-gap-xs {
  row-gap: 4px;
}

.v-gap-s {
  row-gap: 8px;
}

.v-gap-m {
  row-gap: 12px;
}

.v-gap-l {
  row-gap: 16px;
}

.v-gap-xl {
  row-gap: 24px;
}

.v-gap-xxl {
  row-gap: 32px;
}

.v-gap-display {
  row-gap: 48px;
}

.h-gap-xxs {
  column-gap: 2px;
}

.h-gap-xs {
  column-gap: 4px;
}

.h-gap-s {
  column-gap: 8px;
}

.h-gap-m {
  column-gap: 12px;
}

.h-gap-l {
  column-gap: 16px;
}

.h-gap-xl {
  column-gap: 24px;
}

.h-gap-xxl {
  column-gap: 32px;
}

.h-gap-display {
  column-gap: 48px;
}

.min-h-l {
  min-height: 248px;
}

.max-h-l {
  max-height: 248px;
}

/* SEMANTICS */

.invert {
  filter: invert();
}

.flex {
  display: flex;
}

.flex-vertical {
  display: flex;
  flex-direction: column;
}

.overlay-tools {
  position: absolute;
  top: 12px;
  left: 12px;
  right: 12px;
  height: 28px;
  border-radius: 4px;
}

.height-100 {
  height: 100%;
}

#keystone-toasts-wrapper {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 12px;
  right: 0;
  bottom: 0;
  width: 100%;
  pointer-events: none;
  z-index: 100;
  display: flex;
  justify-content: center;
}

#keystone-user-toasts-wrapper {
  position: absolute;
  z-index: 99;
  left: 0;
  top: 12px;
  right: 0;
  bottom: 24px;
  width: 100%;
  pointer-events: none;
  z-index: 100;
  display: flex;
  align-items: flex-end;
  justify-content: center;
}

input {
  outline: 0;
  border: none;
  background-color: var(--blue-black-4);
  padding: 12px 16px;
  box-sizing: border-box;
  border-radius: 6px;
  line-height: 18px;
}

a, a:visited {
  color: var(--ks-blue-dark);
}

.section-label {
  font-weight: 600;
}

.ks-input {
  font-family: 'SF Mono';
  padding: 8px 12px;
}
.ks-select {
  width: initial;
  background-color: var(--ks-divider-light);
  border-radius: 4px;
}

.margin-32 {
  margin: 32px;
}

.min-120 {
  min-width: 120px;
}

.min-160 {
  min-width: 160px;
}

.max-160 {
  max-width: 160px;
}

.round-12 {
  border-radius: 12px;
}

.round-8 {
  border-radius: 8px;
}

.max-640 {
  max-width: 640px;
}

#state-test {
  position: absolute;
  background-color: pink;
  z-index: 4;
}

p.white {
  color: white;
}

p.black {
  color: black;
}

a.black {
  color: black;
}

.blue-dark {
  color: var(--ks-blue-dark);
}

.pad-top-12 {
  padding-top: 12px;
}

.pad-top-8 {
  padding-top: 8px;
}

.ks-menu-content {
  position: absolute;
  right: 75%;
  box-shadow: var(--ks-box-shadow);
  padding: 12px;
  border: 1px solid var(--ks-divider);
  border-radius: 8px;
  top: 75%;
  z-index: 2;
  background-color: white;
  width: max-content;
  display: flex;
}

.visx-tooltip {
  box-shadow: var(--ks-box-shadow) !important;
  padding: 8px !important;
  border: 1px solid var(--ks-divider-transparent-light) !important;
  border-radius: 6px !important;
  font-family: "Poppins", sans-serif !important;
  z-index: 4 !important;
  background-color: white !important;
  color: black !important;
  width: max-content !important;
  display: flex;
  pointer-events: all !important;
}

.max-content {
  width: max-content;
}

.ks-menu-content.top-right {
  top: initial;
  bottom: 132%;
  right: 0;
  z-index: 10;
} 

.ks-menu-content p {
  display: flex;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.14s ease-in-out;
}

.ks-menu-content p:hover {
  background-color: var(--ks-divider-light);
}

.invert {
  filter: invert();
}

.box-shadow-up {
  box-shadow: var(--ks-box-shadow-up);
}

.flex-2 {
  display: flex;
  flex: 2;
}

.flex-3 {
  display: flex;
  flex: 3;
}

.uppercase {
  text-transform: uppercase;
  letter-spacing: 0.4px;
}

p.secondary {
  color: var(--ks-blue-dark);
}

p.secondary.on-brand {
  color: rgb(121, 62, 6);
}

.space-around {
  justify-content: space-around;
}

.border-left {
  border-left: 1px solid var(--ks-divider-light);
}

button {
  background: initial;
  color: initial;
  border: initial;
  box-shadow: initial;
  cursor: pointer;
  min-width: initial;
}

.overflow-auto {
  overflow: auto;
}

/* Content Wrapper with Flex */
#content-wrapper {
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

/* Menu Styles */
#keystone-menu {
  background-color: transparent;
  height: 100%;
  flex-shrink: 0;
  flex-grow: 0;
  transition: flex-basis 0.3s ease, transform 0.3s ease;
}

#keystone-menu.closed {
  transform: translateX(0%);
  flex-basis: 0;
}

#keystone-menu.open {
  transform: translateX(0);
}

/* Tray Styles */
#keystone-tray {
  background-color: #fff;
  width: 480px;
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 2;
  box-shadow: -12px 0 32px var(--ks-divider-transparent-light);
  transform: translateX(100%);
  transition: transform 0.18s ease-in-out;
}

#keystone-tray.open {
  transform: translateX(0);
}

#keystone-menu:last-of-type {
  display: none;
}

.sf-pro {
  font-family: 'SF Pro';
}


.sf-mono {
  font-family: 'SF Mono';
}

table {
  box-shadow: unset;
  border-radius: unset;
  background-color: unset;
}

.number {
  font-family: 'SF Mono';
}

.text-mono {
  font-family: 'SF Mono';
  text-wrap: wrap;
}

.hero-text {
  font-weight: 600;
}

.pad-b-4 {
  padding-bottom: 12px;
}

.align-start {
  align-items: flex-start;
}

.align-end {
  align-items: flex-end;
}

.justify-end {
  justify-content: flex-end;
}

.relative {
  position: relative;
}

.ks-button {
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 8px;
}

.ks-button:hover {
  background: var(--ks-divider);
}

.ks-button.large {
  padding: 8px 24px;
}

.ks-button.white {
  background-color: white;
}

.ks-button.white:hover {
  background: var(--ks-divider-light);
}

.ks-button.brand {
  background-color: var(--ks-brand-color);
}

.ks-description {
  line-height: 20px;
}

.keystone-divider {
  background-color: var(--ks-divider);
  height: 1px;
}

.width-100 {
  width: 100%;
}

.no-pad-top {
  padding-top: 0;
}

.no-pad-bot {
  padding-bottom: 0;
}

.pad-right-24 {
  padding-right: 24px;
}

.pad-left-24 {
  padding-left: 24px;
}


.pad-right-16 {
  padding-right: 16px;
}

.keystone-notification-circle {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--ks-brand-color);
  position: absolute;
  right: 16px;
}

.desktop-hide {
  display: none !important;
}

.width-50 {
  flex-basis: 20%;
  flex-grow: 1;
}

.flex-grow {
  flex-grow: 1;
}

.flex-container {
  display: flex;
  flex-direction: row; /* use column if that's what's needed for vertical layout */
  align-items: stretch; /* ensures children stretch to fill container height */
  justify-content: space-between;
}

.brand-color {
  color: var(--ks-brand-color);
}

.ks-button.b-size-xl {
  padding: 12px 18px;
}

.ks-button.b-size-l {
  padding: 8px 16px;
}

.vertical.reverse {
  flex-direction: column-reverse;
}

.flex-end {
  justify-content: flex-end;
}

.no-shadow {
  box-shadow: none !important;
}

.no-border {
  border: none !important;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 4;
  opacity: 1;
}

.modal-content {
  max-width: 640px;
  max-height: 98%;
}

.no-pad {
  padding: 0;
}

.no-v-gap {
  row-gap: 0;
}

.white-bg {
  background-color: var(--white);
}

.white-shiny-bg {
  background: linear-gradient(to top right, #ffffff60, #ffffff20);
  border: 1px solid #ffffff80;
}

.white-transparent {
  color: rgba(255, 255, 255, 0.5);
}

.black-bg {
  background-color: var(--black);
}

.blue-bg {
  background-color: var(--blue);
}

.no-pointer {
  pointer-events: none;
}

.blue-transparent-bg {
  background: linear-gradient(to top right, var(--blue), #ffffff00);
}

.yellow-bg {
  background-color: var(--yellow);
}

.yellow-bg.dark-border {
  border: 1px solid var(--ks-brand-color-dark);
}

.grey-bg {
  background-color: var(--grey);
}

@keyframes fadeOut {
  from {
      opacity: 1;
  }
  to {
      opacity: 0;
  }
}

@keyframes fadeIn {
  from {
      opacity: 0;
  }
  to {
      opacity: 1;
  }
}

@keyframes fadeSlideIn {
  from {
      opacity: 0;
      transform: translateY(20px);
  }
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

@keyframes fadeSlideOut {
  from {
      opacity: 1;
      transform: translateY(0);
  }
  to {
      opacity: 0;
      transform: translateY(20px);
  }
}

.fade-out {
  opacity: 1; /* Start with the element hidden */
  animation: fadeOut 0.6s ease-out forwards;
  animation-delay: 0.1s; /* Delay for the second text */
}

.fade-in {
  opacity: 0; /* Start with the element hidden */
  animation: fadeIn 0.6s ease-out forwards; /* Adjust the duration and timing function as needed */
}

.fade-slide-in-d {
  opacity: 0; /* Start with the element hidden */
  animation: fadeSlideIn 0.6s ease-out forwards;
  animation-delay: 0.1s; /* Delay for the second text */
}

.fade-slide-out-d {
  opacity: 1; /* Start with the element hidden */
  animation: fadeSlideOut 0.6s ease-out forwards;
  animation-delay: 0.1s; /* Delay for the second text */
}

.round-s {
  border-radius: 4px;
}

.round-m {
  border-radius: 8px;
}

.no-round-top {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.no-round-bot {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

@media only screen and (max-width: 767px) {
  .modal-content {
    max-width: 96%;
  }

  #content-wrapper {
      flex-direction: column-reverse;
  }

  #keystone-menu.closed {
      transform: translateX(0);
  }
  
  #keystone-menu.open {
      transform: translateX(0);
      flex-basis: 0;
  }

  #keystone-menu:last-of-type {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 4px;
  }

  .max-640 {
      max-width: 100%;
  }

  .min-120 {
      min-width: 80px;
  }
  
  .min-160 {
      min-width: 120px;
  }
  
  .max-160 {
      max-width: 120px;
  }

  .mobile-horizontal, .vertical.mobile-horizontal {
      flex-direction: row;
  }

  .reverse {
      flex-direction: column-reverse;
  }
  .mobile-hide {
      display: none !important;
  }
  
  .desktop-hide {
      display: flex !important;
  }
  
  #keystone-tray {
      width: 100%;
      position: absolute;
      right: 0;
      height: 100%;
      z-index: 2;
      box-shadow: -12px 0 32px #E6E8F0;
      transform: translateY(100%);
      transition: transform 0.3s ease;
  }
  
  #keystone-tray.open {
      transform: translateY(0);
  }

  .size-xs {
      font-size: 8px;
      line-height: 10px;
  }

  .line-xs {
      line-height: 12px;
  }
  
  .size-s {
      font-size: 10px;
      line-height: 12px;
  }

  .line-s {
      line-height: 14px;
  }
  
  .size-m {
      font-size: 12px;
      line-height: 14px;
  }

  .line-m {
      line-height: 16px;
  }
  
  .size-l {
      font-size: 14px;
      line-height: 16px;
  }

  .line-l {
      line-height: 18px;
  }
  
  .size-xl {
      font-size: 16px;
      line-height: 18px;
  }

  .line-xl {
      line-height: 20px;
  }

  .size-display {
      font-size: 20px;
      line-height: 22px;
  }

  .pad-xs {
      padding: 4px;
  }
  
  .pad-s {
      padding: 8px;
  }
  
  .pad-m {
      padding: 12px;
  }
  
  .pad-l {
      padding: 16px;
  }
  
  .pad-xl {
      padding: 20px;
  }
  

  .pad-h-xs {
      padding-left: 4px;
      padding-right: 4px;
  }
  
  .pad-h-s {
      padding-left: 6px;
      padding-right: 6px;
  }
  
  .pad-h-m {
      padding-left: 12px;
      padding-right: 12px;
  }
  
  .pad-h-l {
      padding-left: 16px;
      padding-right: 16px;
  }
  
  .pad-h-xl {
      padding-left: 20px;
      padding-right: 20px;
  }

  .v-gap-xxs {
      row-gap: 0px;
  }
  
  .v-gap-xs {
      row-gap: 2px;
  }
  
  .v-gap-s {
      row-gap: 6px;
  }
  
  .v-gap-m {
      row-gap: 8px;
  }
  
  .v-gap-l {
      row-gap: 12px;
  }
  
  .v-gap-xl {
      row-gap: 16px;
  }
  
  .v-gap-xxl {
      row-gap: 20px;
  }
  
  .v-gap-display {
      row-gap: 28px;
  }

  .h-gap-xxs {
      column-gap: 0px;
  }
  
  .h-gap-xs {
      column-gap: 2px;
  }
  
  .h-gap-s {
      column-gap: 4px;
  }
  
  .h-gap-m {
      column-gap: 6px;
  }
  
  .h-gap-l {
      column-gap: 8px;
  }
  
  .h-gap-xl {
      column-gap: 12px;
  }
  
  .h-gap-xxl {
      column-gap: 16px;
  }
  
  .h-gap-display {
      column-gap: 24px;
  }

  .width-50 {
      flex-basis: 40%;
      flex-grow: 1;
  }

  .no-pad-top {
      padding-top: 0;
  }
  
  .no-pad-bot {
      padding-bottom: 0;
  }

  .keystone-divider.horizontal-mobile {
      width: 100% !important;
      height: 1px !important;
  }
}

#keystone-content {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  flex: 1;
  background-color: white;
  box-sizing: border-box;
  margin: 0 12px 12px 16px;
  box-shadow: 0 12px 32px #E6E8F0;
  border-radius: 8px;
  transition: all 0.3s ease;
}

h4 {
  margin: 0;
}

#content-header {
  padding: 16px 16px 16px 20px;
  margin: 12px 12px 12px 16px;
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: var(--ks-box-shadow);
}

#content-body {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  margin: 0 32px;
  box-sizing: border-box;
  border-radius: 24px;
  flex-direction: column;
}

.upload-section {
  background-image: radial-gradient(circle, #E6E8F0 2px, transparent 2px), radial-gradient(circle, #E6E8F0 2px, transparent 2px), radial-gradient(circle, #E6E8F0 2px, transparent 2px), radial-gradient(circle, #E6E8F0 2px, transparent 2px);
  background-position: left top, right top, left bottom, left top;
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 12px 4px, 4px 12px, 12px 4px, 4px 12px;
}

#content-footer {
  display: flex;
  justify-content: space-around;
  margin: 32px;
  box-sizing: border-box;
  border: 1px solid #E6E8F0;
  border-radius: 12px;
  padding: 14px;
}

.info-block {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.info-block h4 {
  margin: 0;
}

.info-block.outer-divider {
  border-right: 1px solid #E6E8F0;
  border-left: 1px solid #E6E8F0;
  margin: 0 24px;
  padding: 0 24px;
}

#inventory-placeholder {
  border-radius: 100%;
}

.file-label {
  text-decoration: underline;
  cursor: pointer;
}

#file-upload {
  display: none;
}

#content-body.product-example {
  border-radius: 0;
  display: flex;
  align-items: flex-start;
  row-gap: 12px;
  overflow-y: scroll;
  justify-content: flex-start;
  /* show scrollbar */
  scrollbar-width: thin;
  scrollbar-color: #E6E8F0 #f6f6f6;
  padding-top: 12px;
}

.product-description {
  font-family: 'SF Pro';
}
#product-documents {
  display: flex;
  column-gap: 8px;
  row-gap: 8px;
  flex-wrap: wrap;
}

.specification-group {
  display: block;
}

.product-manufacturer {
  font-weight: 600;
}

.product-manufacturer a {
  color: black;
  text-decoration: none;
}

.total-cost {
  font-family: 'SF Mono';
  line-height: 24px;
}

.vertical {
  flex-direction: column;
}

.horizontal {
  flex-direction: row;
}

.product-image-wrapper {
  display: flex;
  flex: 1;
  padding: 32px;
}

.product-image {
  object-fit: contain;
  border: 1px solid white;
  box-sizing: border-box;
  border-radius: 8px;
  width: 48px;
  height: 48px;
}

.product-image.width-100 {
  width: 100%;
  height: auto;
}

.symbol-image {
  width: 100%;
  object-fit: contain;
  background-color: white;
  box-sizing: border-box;
  border-radius: 8px;
}

#product-example-header {
  border-radius: 16px;
  width: 100%;
}

#product-example-header p {

}


#product-example-content {

}

.product-price, .product-quantity {
  font-family: 'SF Mono';
}

#product-market-data {
  display: flex;
  row-gap: 4px;
  border: 1px solid #E6E8F0;
  padding: 12px;
  border-radius: 6px;
}

#product-internal-data {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  border: 1px solid #E6E8F0;
  padding: 12px;
  border-radius: 6px;
}



input.simple {
  border: none;
  background: #E6E8F0;
  border-radius: 4px;
  padding: 8px 4px;
  text-align: center;
}

.v-pad-16 {
  padding: 16px 0;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.center-text {
  text-align: center;
  justify-content: center;
}

.img-12 {
  width: 12px;
  height: 12px;
}

.keystone-card {
  border: 1px solid var(--ks-divider-transparent-light);
  border-radius: 6px;
  box-shadow: var(--ks-box-shadow-shallow);
  background: white;
  position: relative;
  box-sizing: border-box;
}

.table-row {
  border-bottom: 1px solid var(--ks-divider-transparent-light);
  position: relative;
  box-sizing: border-box;
  background-color: white;
  border-radius: 0;
}

.table-row:last-of-type {
  border-bottom: none;
}

.keystone-card-secondary {
  background-color: var(--ks-divider-superlight);
  border: 1px solid var(--ks-divider-transparent-light);
  border-radius: 6px;
  position: relative;
  box-sizing: border-box;
  box-shadow: var(--ks-box-shadow);
}

.keystone-card-secondary.no-shadow {
  box-shadow: none;
}

.bg-secondary {
  background-color: var(--ks-divider-superlight);
}

.bg-brand {
  background-color: var(--ks-brand-color);
}

.keystone-card.selected {
  border: 1px solid var(--ks-divider-dark);
}

.keystone-card-footer {
  border-top: 1px solid var(--ks-divider-light);
  padding: 24px;
  box-sizing: border-box;
}

#linked-part {
  border: 1px solid var(--ks-divider);
  height: 48px;
  width: 48px;
  position: absolute;
  left: calc(50% - 24px);
  background-color: white;
  border-radius: 100%;
  padding: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: var(--ks-box-shadow-shallow);
  z-index: 2;
  top: calc(50% - 24px);
}

.space-around {
  justify-content: space-around;
}

.pad-bot-none {
  padding-bottom: 0;
}

#content-tab {
  padding: 2px;
}

.right-align {
  text-align: right;
}

.clickable {
  cursor: pointer;
  transition: all 0.08s ease-in-out;
}
.overflow-x-hidden {
  overflow-x: hidden;
}

.clickable:hover {
  border-color: var(--ks-divider-dark);
  box-shadow: var(--ks-box-shadow);
}

.cta-bg {
  background-color: var(--ks-brand-color);
  box-shadow: none;
}

.cta-bg-secondary {
  background-color: var(--ks-divider-light);
}

.modal {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 3;
  transition: all 0.3s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
}

.no-background {
  background-color: transparent;
}

input, textarea, select {
  -webkit-text-size-adjust: 100%;
  text-size-adjust: 100%;
}

.ks-button.no-padding {
  padding: 0;
}

.transparent-border {
  border: 1px solid transparent;
}

@media only screen and (max-width: 767px) {
  #keystone-content {
      margin: 0;
      border-radius: 0;
  }

  #content-header {
      padding: 8px;
      margin: 0;
      flex-direction: column;
  }

  #content-tab {
      width: 100%;
  }

  #inventory-search-wrapper {
      width: 100%;
  }

  .vertical.reverse-column {
    flex-direction: column-reverse;
  }

  .mobile-vertical.reverse {
    flex-direction: column-reverse;
  }

  .mobile-vertical.align-center {
    align-items: flex-start;
  }

  .product-image {
    width: 32px;
    height: 32px;
  }

  .flex-2.mobile-flex-reset, .flex-1.mobile-flex-reset {
    flex: initial;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(160px, 1fr));
    /* This is better for small screens, once min() is better supported */
    /* grid-template-columns: repeat(auto-fill, minmax(min(200px, 100%), 1fr)); */
    gap: 0.4rem;
    padding-bottom: var(--footer-height);
  }

  .mobile-width-100 {
    width: 100%;
  }


  .mobile-vertical {
    flex-direction: column;
  }

  .mobile-flex-1 {
    flex: 1;
  }

  input, textarea, select {
    font-size: 16px !important;
  }

  #keystone-user-toasts-wrapper {
    bottom: initial;
    top: var(--header-height);
  }

  .round-s {
    border-radius: 2px;
  }
  
  .round-m {
    border-radius: 6px;
  }

  .no-round-top {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .no-round-bot {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .no-pad-left {
    padding-left: 0;
  }

  .no-pad-right {
    padding-right: 0;
  }
}