#product {
    display: flex;
    padding-top: 132px;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    box-sizing: border-box;
    width: 100%;
    align-items: center;
}

#product button {
    cursor: pointer;
}

#product-content-wrapper {
    max-width: 960px;
    display: flex;
    column-gap: 48px;
}

#product-details-wrapper {
    display: flex;
    flex-direction: column;
}

#product-manufacturer, #product-number {
    margin: 0;
}

#product-manufacturer {
    font-weight: 400;
    line-height: 16px;
}

#product-tags-wrapper{
    display: flex;
    column-gap: 12px;
}

#specs-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates a 5-column layout */
    gap: 10px; /* Creates a 10px gap between grid items */
}

.product-tag {
    display: flex;
    column-gap: 6px;
    border-radius: 16px;
    padding: 4px 12px 4px 8px;
    font-size: 14px;
    font-weight: 500;
    align-items: center;
}

.product-tag.green {
    color: #31684B;
    background-color: #D8EBE1;
}

#product-description-wrapper {
    margin: 16px 0;
}

#product-description-wrapper p {
    color: #5B6384;
}

#product-description-wrapper a {
    color: black;
    text-decoration: underline;
    cursor: pointer;
}

.product-tag.blue {
    color: #314A68;
    background-color: #D8E0EB;
}

#product-footer-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

#product-price-wrapper {
    text-align: right;
}

#product-price {
    font-size: 24px;
    font-weight: 700;
}

#product-footer-wrapper button {
    display: flex;
    column-gap: 4px;
    height: 44px;
    align-items: center;
    background: white;
    color: black;
    border: none;
    border-radius: 12px;
    padding: 0 16px 0 12px;
}

#product-quantity, #product-offer {
    color: #62677D;
    font-weight: 500;
    line-height: 12px;
    font-size: 16px;
}

#product-image-wrapper {
    width: 320px;
    height: 320px;
    margin-bottom: 16px;
    display: flex;
}

#product-image {
    background-color: white;
    width: 320px;
    height: 320px;
    border-radius: 24px;
    box-shadow: 0 0 32px #E6E8F0;
}

#product-purchase-wrapper {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    column-gap: 16px;
}

#product-purchase-type-wrapper {
    background: #EDF0FB;
    padding: 4px;
    border-radius: 12px;
    display: flex;
    column-gap: 4px;
    height: 44px;
    box-sizing: border-box;
    margin-bottom: 16px;
    transition: 0.1s all ease-in-out;
}

#product-specification-button-icon, #product-expand-button-icon {
    width: 24px;
    height: 24px;
}

#product-specification-button:hover {
    background: black;
    color: white;
}

#product-specification-button:active {
    scale: 0.95;
}

#product-specification-button:hover img {
    filter: invert(1);
}
#product-purchase-button-buy, #product-purchase-button-offer {
    display: flex;
    flex: 1;
    justify-content: center;
    padding: 4px 0;
    border-radius: 8px;
    align-items: center;
    background: none;
    border: none;
    box-shadow: none;
    color: #5F6994;
    transition: 0.1s all ease-in-out;
}

#product-purchase-button-buy:hover , #product-purchase-button-offer:hover {
    background: #D8E0EB;
}

#product-purchase-button-buy.active, #product-purchase-button-offer.active {
    background: white;
    color: black;
    border: none;
}

#product-purchase-button-buy:active, #product-purchase-button-offer:active {
    scale: 0.95;
}

#product-purchase-quantity-wrapper, #product-purchase-offer-wrapper {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    column-gap: 16px;
    flex: 3;
}

#product-purchase-button {
    width: 100%;
    background: black;
    color: white;
    border-radius: 12px;
    border: none;
    height: 44px;
    flex: 2;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 16px;
    font-weight: 500;
}

#product-purchase-button:hover {
    background: #FFB11C;
    color: black;
}

#product-purchase-button:active {
    scale: 0.95;
}

#product-purchase-availability-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}

#product-purchase-quantity-sub-text {
    font-size: 12px;
    color: #5B6384;
    font-weight: 500;
    line-height: 16px;
}

#product-purchase-quantity-text, #product-purchase-offer-text {
    font-weight: 500;
    line-height: 20px;
}

#product-purchase-quantity-input, #product-purchase-offer-input {
    font-size: 18px;
    width: 132px;
    background: none;
    border: none;
    text-align: right;
    height: 44px;
    box-sizing: border-box;
}

#product-purchase-subtotal {
    font-weight: 600;
    margin-left: 24px;
}

#product-purchase-offer-input {
    padding-right: 16px;
}

#product-purchase-quantity-input {
    padding-right: 16px;
}

#product-purchase-quantity-input-wrapper, #product-purchase-offer-input-wrapper {
    background: #fff;
    border: 1px solid #fff;
    border-radius: 12px;
    display: flex;
    box-shadow: 0 0 32px #E6E8F0;
    transition: all 0.1s ease-in-out;
}

#product-purchase-quantity-input-wrapper:hover , #product-purchase-offer-input-wrapper:hover {
    border: 1px solid #D8E0EB;
}

#product-purchase-quantity-x-wrapper, #product-purchase-offer-x-wrapper {
    position: absolute;
}

#product-purchase-quantity-x, #product-purchase-offer-x {
    position: relative;
    left: 12px;
    top: 10px;
    color: #62677D;
    font-size: 16px;
    font-weight: 500;
}

#product-image-controls-overlay {
    position: absolute;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 320px;
    height: 320px;
    flex-direction: column;
    padding: 24px;
    box-sizing: border-box;
}

#product-image-controls-overlay button {
    min-width: initial;
    line-height: unset;
}

#product-image-button-expand {
    width: 36px;
    height: 36px;
    background: #EDF0FB;
    border: none;
    border-radius: 12px;
    color: #62677D;
    display: flex;
    justify-content: center;
    box-shadow: none;
}

#product-image-button-expand:active {
    scale: 0.95;
}

#product-image-button-expand:hover {
    background: #D8E0EB;
}

#product-image-button-navigate {
    display: flex;
    justify-content: space-around;
    width: 100%;
}

#product-image-button-navigate-wrapper {
    display: flex;
    column-gap: 24px;
}

#product-image-button-left {
    border: none;
    display: flex;
    align-items: center;
    background: none;
    box-shadow: none;
    padding: 0;
}

#product-image-button-right {
    justify-self: center;
    border: none;
    display: flex;
    align-items: center;
    background: none;
    box-shadow: none;
    padding: 0;
}

#product-left-button-icon, #product-right-button-icon {
    width: 28px;
    height: 28px;
    transition: all 0.1s ease-in-out;
}

#product-left-button-icon:active, #product-right-button-icon:active {
    scale: 0.95;
}

#product-box-green-icon, #product-box-icon {
    width: 20px;
    height: 20px;
}

#product-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 999;
    display: flex;
    align-items: center;
    justify-content: center;
}

#product-overlay-content-wrapper {
    width: 860px;
    height: 860px;
    max-height: 80%;
    background-color: white;
    border-radius: 24px;
    pointer-events: none;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

#product-overlay-image {
    max-width: 100%;
}

#product-header {
    position: fixed;
    top: 12px;
    left: 12px;
    right: 12px;
    padding: 8px 16px;
    border-radius: 8px;
    z-index: 3;
    background-color: white;
    box-shadow: var(--ks-box-shadow-gray);
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#product-search-results-wrapper {
    position: absolute;
    width: 100%;
    max-width: 540px;
    top: calc(100% + 4px);
    cursor: pointer;
    overflow: hidden;
    z-index: 3;
    border-top: none;
    box-shadow: var(--ks-box-shadow-gray);
}

.product-search-result {
    display: flex;
    align-items: center;
    column-gap: 12px;
    padding: 8px;
    border-radius: 8px;
}

.product-search-result:hover {
    background: #EDF0FB;
    cursor: pointer;
}

.result-content-wrapper {
    display: flex;
    flex-direction: column;
}

.result-content-wrapper p {
    margin: 0;
}

#product-search-results {
    position: relative;
    width: 100%;
    background: white;
    top: 100px;
    box-shadow: 0 0 32px #E6E8F0;
    border: 1px solid #cbcedb;
    border-radius: 12px;
    pointer-events: all;
    padding: 8px;
    box-sizing: border-box;
}

.result-image-wrapper {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    padding: 8px;
    border-radius: 4px;
}

.result-image {
    max-width: 32px;
    max-height: 32px;
}

.result-manufacturer {
    font-size: 14px;
    line-height: 16px;
}

.result-part-number {
    font-size: 18px;
    line-height: 20px;
    font-weight: 600;
}

#product-page-image {
    max-width: 100%;
    object-fit: contain;
    /* border: 1px solid var(--ks-divider-light); */
    box-sizing: border-box;
    border-radius: 8px;
    padding: 24px;
}

#modal-shadow {
    border-radius: 12px;
    box-shadow: 0 0 124px 0 rgba(0, 0, 0, 0.32);
    background-color: var(--white);
    max-width: var(--max-modal-width);
}
@media only screen and (max-width: 767px) {
    #specs-grid {
        display: grid;
        grid-template-columns: repeat(2, 1fr); /* Creates a 5-column layout */
        gap: 10px; /* Creates a 10px gap between grid items */
    }

    #order-add {
        width: 100%;
    }

    #product-page-image {
    }
}