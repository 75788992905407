.banner {
    /* background-color: var(--blue); */
    border-radius: 6px;
    min-height: 210px;
}

.banner-image {
    width: 100%;
    left: 25%;
    object-fit: contain;
    box-sizing: border-box;
    height: 200%;
    rotate: 15deg;
    bottom: -50%;
    position: absolute;
}

.banner-image.saturate {
    filter: saturate(1.6);
}

.banner-item-content {
    z-index: 2;
}

.banner-logo {
    position: absolute;
    width: 72px;
    object-fit: contain;
    /* tint to black */
    filter: brightness(0) invert(1);
}

#banner-text {
    -webkit-text-stroke: 4px var(--yellow);
}

.fix-stroke {
    paint-order: stroke fill;
}
@media only screen and (max-width: 767px) {
    .banner-image {
        width: 100%;
        left: 25%;
        object-fit: contain;
        box-sizing: border-box;
        height: 200%;
        top: -60%;
        rotate: 15deg;
        bottom: -5%;
        position: absolute;
    }
}