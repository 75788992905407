.wordmark {
    width: 146px;
    height: 100%;
}

.wordmark svg {
    width: 100%;
    height: 100%;
}

@media only screen and (max-width: 767px) {
}