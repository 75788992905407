.image-wrapper {
    border: 1px solid transparent;
    background-color: transparent;
    border-radius: 6px;
}

.image-wrapper.image-white-bg {
    background-color: white;
    border: 1px solid white;
}

.image {
    object-fit: contain;
    box-sizing: border-box;
}

.image.width-100 {
    width: 100%;
    height: auto;
}

.image.small {
    width: 32px;
    height: 32px;
}

.image.medium {
    width: 40px;
    height: 40px;
}

.image-overlay {
    position: absolute;
    z-index: 2;
    top: 6px;
    right: 6px;
}

@media only screen and (max-width: 767px) {
    .image-wrapper.mobile-cart-image {
        width: 100%;
        padding: 0;
    }
    
    .image-wrapper.mobile-cart-image .image.small {
        width: 100%;
        height: 124px;
    }
}