.keystone-action {
    border-radius: 4px;
    transition: all 0.12s ease-in-out;
    scale: 1;
}

#category-navigate.keystone-action {
    background: var(--ks-divider-light);
}

.keystone-action.active {
    background-color: white;
}

.keystone-action:active {
    scale: 0.96;
}

.keystone-action p {
    display: flex;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.14s ease-in-out;
}

.keystone-action.breadcrumb p {
    padding: 0;
}

.keystone-action p:hover {
}

.keystone-action:hover {
    background-color: var(--ks-divider-light);
}

.keystone-action.negative {
    color: var(--ks-negative-text);
}

.keystone-action.positive {
    color: var(--ks-positive-text);
}

.keystone-action.breadcrumb {
    background-color: transparent;
}

.keystone-action.breadcrumb p {
    color: var(--ks-blue-dark);
}
.keystone-action.breadcrumb:hover {
    background-color: transparent;
}
.keystone-action.breadcrumb:hover p {
    color: black;
}

.keystone-action.breadcrumb.active p {
    color: black;
}

.keystone-action.on-white.breadcrumb {
    background-color: white;
}

.keystone-action.on-white.breadcrumb:hover {
    background-color: var(--ks-divider-superlight);
}


.keystone-action.on-white.breadcrumb.active {
    background-color: var(--ks-divider);
    scale: 0.96;
}

.keystone-action.disabled, .keystone-action.disabled:hover .keystone-action.disabled p:hover {
    cursor: not-allowed;
    color: var(--ks-divider-dark);
    background-color: white;
}

@media only screen and (max-width: 767px) {
}