.product-inset {
    padding-top: 90px;
}

.hidden-section {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    clip: rect(0, 0, 0, 0);
    overflow: hidden;
    white-space: nowrap; /* Prevent the text from wrapping */
}

.product-grid {
    display: flex;
    flex-wrap: wrap; /* Allows items to wrap into new lines */
}
  
.product-grid > * {
    flex: 1 0 calc((100% - (var(--columns, 3) - 1) * 16px) / var(--columns, 3)); 
    /* 
    Calculation Breakdown:
    - var(--columns, 3): Number of columns (3 is the default value)
    - 16px is the gap; adjust it according to your gap
    */
    box-sizing: border-box; /* Ensures padding and border are included in the total width and height */
}

#cart-count {
    position: absolute;
    background-color: var(--ks-brand-color);
    width: 18px;
    height: 18px;
    border-radius: 12px;
    right: 2px;
    top: 0px;
    box-shadow: var(--ks-box-shadow);
    z-index: 2;
    border: 1px solid var(--black);
}

#cookie-banner {
    position: fixed;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 3;
}

@media only screen and (max-width: 767px) {

}