#store-content {
    
}

#order-request {
    overflow: hidden;
}

.pad-action-button-right {
    padding-right: 34px;
}
@media only screen and (max-width: 767px) {
    #order-request {
        width: 100%;
    }

    .pad-action-button-right {
        padding-right: 28px;
    }

    .mobile-footer-pad {
        padding-bottom: var(--footer-height);
    }

    .mobile-bump-pad {
        padding-bottom: var(--footer-height) + 64px;
    }
}