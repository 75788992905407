#store-header {
    /* background: var(--black); */
    background-color: var(--black);
    border: 2px solid var(--black);
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    border-top: 1px solid rgba(255,255,255,0.08);
    /* border-radius: 6px; */
}

#store-header-footer {
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
}

#product-logo {
    height: 32px;
    filter: invert();
}

#cart-logo {
    width: 24px;
    filter: invert();
    height: 24px;
}

#product-home-button {
    background: unset;
    border: unset;
    padding: 0;
    box-shadow: unset;
    cursor: pointer;
    padding-left: 12px;
}

#product-home-button img {
    content:url(../../../media/materialize-logo-wordmark-black.svg);
    filter: invert();
    width: 144px;
}

#product-home-button:hover {
    scale: 1.02;
}

#product-home-button:active {
    scale: 1;
}

#cart-button {
    background: none;
    width: initial;
    border: none;
    box-shadow: none;
    min-width: initial;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 6px 16px;
    border-radius: 32px;
    border: 1px solid var(--white-transparent);
}

#cart-button:hover {
    background: var(--white-transparent);
}

#cart-button:active {
    scale: 0.95;
}

#cart-button:hover img, #cart-button.active img {
    filter: brightness(0) saturate(100%);
}

#cart-button.active {
    background-color: var(--white-transparent);
}

#product-search-wrapper {
    background: #ffffff20;
    flex: 1;
    max-width: 540px;
    height: 36px;
    border-radius: 6px;
    display: flex;
    align-items: center;
    column-gap: 8px;
    transition: all 0.1s ease-in-out;
}

#product-search-input {
    background: none;
    border: none;
    font-size: 14px;
    font-weight: 500;
    font-family: "SF Mono";
    height: 100%;
    width: 100%;
    padding-left: 36px;
    border-radius: 8px;
    color: var(--white);
}

#product-search-input::placeholder {
    color: #fff;
}

#product-search-wrapper.results {
    /* border-bottom-left-radius: 0;
    border-bottom-right-radius: 0; */
}

/* #product-search-wrapper:hover {
    background: #D8E0EB;
} */

#product-search-icon {
    width: 24px;
    height: 24px;
    margin-left: 8px;
    position: absolute;
    filter: invert();
}

#logo-byline {
    position: absolute;
    left: 100%;
    width: 100%;
}

@media only screen and (max-width: 1487px) {
    /* #store-header {
        border-radius: 0;
    } */
}

@media only screen and (max-width: 767px) {
    #store-header {
        border-radius: 0;
    }
    #product-search-wrapper {
        flex: 2;
    }

    #product-logo {
        height: 32px;
        border-radius: 16px;
    }

    #product-home-button img {
        content: url(https://shop.trymaterialize.com/materialize-logo-white.png);
        max-width: 42px;
    }
}