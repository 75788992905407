#store-sidebar {
 min-width: 320px;
 max-width: 320px;
}

#category-select {
    display: none;
}

#categories-wrapper {

}

@media only screen and (max-width: 767px) {
    #store-sidebar {
       width: initial;
       min-width: initial;
       position: fixed;
       z-index: 3;
       bottom: 6px;
       left: 64px;
       right:0;
    }

    #category-select {
        display: flex;
        background-color: var(--black);
        color: white;
        border-radius: 6px;
    }

    #category-select .keystone-action:hover, #category-select .keystone-action:active {
        background-color: var(--ks-brand-secondary);
    }

    #categories-wrapper {
        display: none;
    }

    #categories-wrapper.menu-visible {
        display: flex;
        background-color: var(--yellow);
        box-shadow: none;
    }

    #footer-links {
        display: none;
    }
    
    #verified-mfg {
        display: none;
    }
}